
import React, { useState } from "react"
import { useWakeLock } from 'react-screen-wake-lock';

import { rhythm } from "../utils/typography"

function WakeLock() {
    const [checked, setChecked] = useState(false);

    const { isSupported, released, request, release } = useWakeLock({
        onError: () => alert('An error happened 💥'),
        onRelease: () => setChecked(false),
    });

    function handleChange(value) {
        setChecked(value);
        if (value) request();
        else release();
    }

    if (isSupported) {
        return (
            <div>
                <input
                    style={{
                        width: rhythm(1),
                        height: rhythm(1),
                        verticalAlign: 'middle'
                    }}
                    type="checkbox"
                    id="sreenLock"
                    onChange={(e) => handleChange(e.target.checked)}
                    checked={checked}
                >                
                </input>
                <label 
                    style={{
                        paddingLeft: rhythm(1/2),
                    }}
                    htmlFor="screenLock">Keep device awake</label>
            </div>
        );
    }
    return (
        <div>
        </div>
    )
}

export default WakeLock;