/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { rhythm } from "../utils/typography"

function Ingredients({ recipeIngredient }) {
  const { site } = useStaticQuery(
    graphql`
       query {
         site {
           siteMetadata {
             title
             description
             author
           }
         }
       }
     `
  )

  const recipeIngredientArray = recipeIngredient.split("\n-").map(vae => vae.replace('-', '').trim());

  return (
    recipeIngredientArray.map(
      (ingredient, index) =>
        <li key={ingredient}>
            <input 
            style={{
                    width: rhythm(1),
                    height: rhythm(1),
                    verticalAlign: 'middle'
                }}
            id="ingredient-{index}" type="checkbox" ></input>
            <label style={{
                paddingLeft: rhythm(1/2),
            }}
            htmlFor="ingredient-{index}">{ingredient}</label>               
        </li>
    )
  )
}

export default Ingredients
